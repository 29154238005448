/**
 * postFormData
 * @param {string} url
 * @param {FormData} formData
 * @param {Object} options
 * @param {string} options.credentials
 * @param {string} options.method
 * @param {string} options.responseType
 * @returns {Promise}
 */
export function postFormData(url, formData, { credentials = null, method = "POST", responseType = "json" }) {
  const API_URL = process.env.VUE_APP_API_URL;
  const request = new XMLHttpRequest();
  const promise = new Promise((resolve, reject) => {
    request.onreadystatechange = () => {
      if (request.readyState == 4 && request.status == 200) {
        resolve(request.response);
      } else if (request?.response?.error) {
        reject(request.response);
      }
    };
  });

  request.open(method, `${API_URL}/${url}`, true);
  request.responseType = responseType;

  if (credentials) {
    request.setRequestHeader("X-Auth-Token", credentials);
  }

  request.send(formData);
  return promise;
}

export default postFormData;
